import {Component, Prop, Watch} from 'vue-property-decorator';
import { Vue, Options } from 'vue-class-component'
import user from '@/store/user';
import {CONFIG_ENV, URL_API} from '@/config';
import {getModule} from 'vuex-module-decorators';
import {TUser} from '@/types/TUser';
type TCategory ={ pid:string, name:string, isParentForArticles:string};

@Options({
    name: "CategoryImg",
    components: {}
})
export default class CategoryImg extends Vue {
    @Prop({ default: { pid:'', name:'', isParentForArticles:'n'} }) public category!: TCategory;
    public urlToJPG = CONFIG_ENV.URL_CATEGORY.getJPG;
}
