import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-00f407e5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "q-pa-sm ecran-container" }
const _hoisted_2 = {
  class: "q-pa-md flex__column--center",
  style: {"max-width":"30rem","margin":"auto"}
}
const _hoisted_3 = {
  key: 2,
  style: {"display":"flex","justify-content":"flex-end"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_form = _resolveComponent("q-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_q_form, {
        onSubmit: _ctx.onSubmit,
        onReset: _ctx.onReset,
        class: "q-gutter-md"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_select, {
            outlined: "",
            readonly: _ctx.adresa.slid != '0',
            modelValue: _ctx.adresa.codTara,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.adresa.codTara) = $event)),
            options: _ctx.countryOptions,
            label: "Tara",
            "option-value": "code",
            "option-label": "name",
            class: "form__input",
            "lazy-rules": "",
            "emit-value": "",
            "map-options": "",
            rules: [ val => val && val.length > 0 || 'Completati tara']
          }, null, 8, ["readonly", "modelValue", "options", "rules"]),
          (_ctx.adresa.codTara == 'RO')
            ? (_openBlock(), _createBlock(_component_q_select, {
                key: 0,
                outlined: "",
                readonly: _ctx.adresa.slid != '0',
                "use-input": "",
                modelValue: _ctx.adresa.adresaCodJudet,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.adresa.adresaCodJudet) = $event)),
                options: _ctx.judeteOptions,
                label: "Judetul",
                "option-value": "code",
                "option-label": "name",
                class: "form__input",
                "lazy-rules": "",
                "emit-value": "",
                "map-options": "",
                rules: [ val => val && val.length > 0 || 'Completati judetul']
              }, null, 8, ["readonly", "modelValue", "options", "rules"]))
            : (_openBlock(), _createBlock(_component_q_input, {
                key: 1,
                outlined: "",
                readonly: _ctx.adresa.slid != '0',
                modelValue: _ctx.adresa.denJudet,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.adresa.denJudet) = $event)),
                label: "Judetul",
                class: "form__input",
                "lazy-rules": "",
                rules: [ val => val && val.length > 0 || 'Completati judetul']
              }, null, 8, ["readonly", "modelValue", "rules"])),
          _createVNode(_component_q_input, {
            outlined: "",
            readonly: _ctx.adresa.slid != '0',
            modelValue: _ctx.adresa.adresaLocalitate,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.adresa.adresaLocalitate) = $event)),
            label: "Localitatea",
            class: "form__input",
            "lazy-rules": "",
            rules: [ val => val && val.length > 0 || 'Completati localitatea']
          }, null, 8, ["readonly", "modelValue", "rules"]),
          _createVNode(_component_q_input, {
            outlined: "",
            readonly: _ctx.adresa.slid != '0',
            modelValue: _ctx.adresa.adresaAdresa,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.adresa.adresaAdresa) = $event)),
            label: "Adresa (strada si nr)",
            class: "form__input",
            "lazy-rules": "",
            rules: [ val => val && val.length > 0 || 'Completati Strada si Nr']
          }, null, 8, ["readonly", "modelValue", "rules"]),
          (_ctx.adresa.slid == '0')
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_q_btn, {
                  label: "Reset",
                  type: "reset",
                  color: "primary",
                  flat: "",
                  class: "q-ml-sm"
                }),
                _createVNode(_component_q_btn, {
                  label: "Salveaza",
                  type: "submit",
                  color: "primary"
                })
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["onSubmit", "onReset"])
    ])
  ]))
}