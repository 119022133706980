import {Component, Prop, Watch} from 'vue-property-decorator';
import { Vue, Options } from 'vue-class-component'
import {TProductBasket} from '@/types/TProductBasket';
import user from '@/store/user';
import {getModule} from "vuex-module-decorators";
import {TUser} from "@/types/TUser";
import {TAdresaLivrare} from "@/types/TAdresaLivrare";
import {TOfferHeader} from "@/types/TOfferHeader";
import {TArticleOfferedFromSales} from "@/types/TArticleOfferedFromSales";
import extend from 'quasar/src/utils/extend.js';;
import {timeUntilFutureDate} from '@/modules/utils'
import nomenclatoare from "@/store/nomenclatoare";
import {ServiceCerere} from '@/services/ServiceCerere';
import {CONFIG_ENV} from "@/config";
import {ServiceFavorites} from "@/services/ServiceFavorites";
import favorites from "@/store/favorites";
import {ServiceAdreseLivrare} from "@/services/ServiceAdreseLivrare";
import {ServiceOffer} from "@/services/ServiceOffer";
import eventbus from "@/store/eventbus";
import EditQuantityArticleCerere from '@/components/EditQuantityArticleCerere/EditQuantityArticleCerere.vue';
import BrowseCategories from '@/pages/BrowseCategories/BrowseCategories.vue';
import BrowseArticles from '@/pages/BrowseArticles/BrowseArticles.vue';
import {TEnumPlacaBara} from "@/types/TEnumPlacaBara";
import latinize from "latinize";
import NomEditAdresaLivrare from '@/components/NomEditAdresaLivrare/NomEditAdresaLivrare';


type TProductBasketMarkedFavorites = TProductBasket & {isInFavorite:boolean};
type TAdresa = TAdresaLivrare | null;

@Options({
    name: "Cerere",
    components: {BrowseCategories, BrowseArticles, EditQuantityArticleCerere, NomEditAdresaLivrare}
})
export default class Cerere extends Vue {
    @Prop({ default: null }) public readonly propOfferId!: string;
    @Prop() public closeHandler!: (needRefresh: boolean)=>void;
    public termenCerere='';
    public nrComandaCerere='';
    public nrComandaCerereInitial='';
    public slidAdresaLivrare='';
    public isEditingAsNewRequest=false;
    public showBrowseArticles = false;
    public myLocale=CONFIG_ENV.myLocale;
    public pidForBrowseCategory = '0';
    public pidForBrowseCategoryHasArticles = false;
    public adrese:TAdresaLivrare[]=[];
    public offerHeader:TOfferHeader={
        offerId: '',
        offerSlid: '',
        status: '',
        trackCreationDate: '',
        trackDateOfertare: '',
        trackDateAccept: '',
        dateWhenOfferExpire:'',
        termenLivrareSolicitat:'',
        qKg:null,
        valFinalaFaraTvaRON:null,
        valTvaRON:null,
        valFinalaCuTvaRON:null,
        valFinalaFaraTvaEUR:null,
        valTvaEUR:null,
        valFinalaCuTvaEUR:null,
        valFinalaFaraTvaHUF:null,
        valTvaHUF:null,
        valFinalaCuTvaHUF:null,
        idValuta:'RON',
        ComandaClient:'',
        slidAdresaLivrare:0,
        val_discount_linii_ron:0,
        val_discount_oferta_ron:0,
        val_discount_linii_eur:0,
        val_discount_oferta_eur:0,
        val_discount_linii_huf:0,
        val_discount_oferta_huf:0,
        invoices:null,
        observatii_asm:''
    };
    
    public dialogAdresa=false;
    public dialogTransitionShow ='';
    public dialogTransitionHide ='';
    public inputFreeTextComments='';
    public selectedAdresa:TAdresaLivrare={
        slid:'0',
        appid: '',
        cif: '',
        adresaCodJudet: '',
        adresaLocalitate: '',
        adresaAdresa: '',
        adresaCodPostal: '',
        tipAdresa: '',
        codTara: '',
        navisionid: '',
        denJudet: '',
        isNewAddress: '0'
    }
    public adresa:TAdresaLivrare={
        slid: '',
        appid: '',
        cif: '',
        adresaCodJudet: '',
        adresaLocalitate: '',
        adresaAdresa: '',
        adresaCodPostal: '',
        tipAdresa: '',
        codTara: '',
        navisionid: '',
        denJudet: '',
        isNewAddress: '0'
    };
    public products:TProductBasketMarkedFavorites[]=[];
    declare public $refs: any;
    public appidToBeTogglesWithFavorites = '';
    public userStore = getModule(user);
    public storeNomenclatoare = getModule(nomenclatoare);
    public storeFavorites = getModule(favorites);
    public urlToJPG = CONFIG_ENV.URL_CATEGORY.getJPG;
    public EventBusStore = getModule(eventbus);
    public changedItemsInOffer = false;

    private timeUntilFutureDate(pStringDate: string, pformat:string){
        return timeUntilFutureDate(pStringDate,pformat)
    }

    public toggleArticleInFavorites(item:TProductBasketMarkedFavorites,indexInProductsList:number) {
        const vueInst = this;
        console.log('item=%o',item)
        vueInst.appidToBeTogglesWithFavorites=item.appid;
        if (item.isInFavorite) {
            ServiceFavorites.removeProductFromFavorites(item.productCode).then(response=>{
                if(response.status=='success'){
                    vueInst.storeFavorites.remove_product_from_favorites(item);
                    vueInst.appidToBeTogglesWithFavorites='';
                    vueInst.products[indexInProductsList].isInFavorite=vueInst.storeFavorites.favorites.some(favorite=>{return favorite.productCode==item.productCode})
                    vueInst.$q.notify({
                        color: 'red-6',
                        textColor: 'white',
                        icon: 'favorite',
                        position: 'top',
                        timeout: 500,
                        message: ' Articolul a fost eliminat din favorite'
                    })
                }
            });
        } else {
            if(item.productCode && item.productCode.length > 0) {//stim exact articolul
                ServiceFavorites.putArticleIntoFavorites(item.productCode,item.um1, item.um2, item.qUm1, item.qUm2).then(response => {
                    vueInst.appidToBeTogglesWithFavorites = '';
                    if (response.status == 'success') {
                        const productForFavorite: TProductBasket = {
                            appid: response.appid,
                            productPid: response.productPid,
                            categoryName: response.categoryName,
                            categoryPid: response.categoryPid,
                            productCode: response.productCode,
                            productName: response.productName,
                            qBuc: item.qBuc,
                            q_um_base: item.qBuc,
                            um1: '',
                            um2: null,
                            um1_to_um2: null,
                            qUm1: 0,
                            qUm2: 0,
                            l: item.l,
                            w: item.w,
                            t: item.t,
                            d: item.d,
                            h: item.h,
                            a: item.a,
                            k: item.k,
                            g: item.g,
                            dorescDebitare: item.dorescDebitare,
                            cuttingLength: item.cuttingLength,
                            cuttingWidth: item.cuttingWidth,
                            enumPlacaBara: item.enumPlacaBara,
                            densitate: item.densitate,
                            observatii:'',
                            nr_ord: item.nr_ord,
                            tip_um: item.tip_um
                        }
                        vueInst.storeFavorites.push_product_to_favorites(productForFavorite);
                        vueInst.products[indexInProductsList].isInFavorite=vueInst.storeFavorites.favorites.some(favorite=>{return favorite.productCode==item.productCode})
                        vueInst.$q.notify({
                            color: 'red-6',
                            textColor: 'white',
                            icon: 'favorite',
                            position: 'top',
                            timeout: 500,
                            message: ' Articolul a fost adaugat in favorite'
                        })
                    }
                })
            }else {
                ServiceFavorites.identifyArticleInDBAndPutIntoFavorites(item.categoryPid.toString(), item.l, item.w, item.t, item.d, item.h, item.a, item.k, item.um1, item.um2, item.qUm1, item.qUm2, item.g)
                    .then(response => {
                        vueInst.appidToBeTogglesWithFavorites = '';
                        if (response.status == 'success') {
                            const productForFavorite: TProductBasket = {
                                appid: response.appid,
                                productPid: response.productPid,
                                categoryName: response.categoryName,
                                categoryPid: response.categoryPid,
                                productCode: response.productCode,
                                productName: response.productName,
                                qBuc: item.qBuc,
                                q_um_base: item.qBuc,
                                um1: '',
                                um2: null,
                                um1_to_um2: null,
                                qUm1: 0,
                                qUm2: 0,
                                l: item.l,
                                w: item.w,
                                t: item.t,
                                d: item.d,
                                h: item.h,
                                a: item.a,
                                k: item.k,
                                g: item.g,
                                dorescDebitare: item.dorescDebitare,
                                cuttingLength: item.cuttingLength,
                                cuttingWidth: item.cuttingWidth,
                                enumPlacaBara: item.enumPlacaBara,
                                densitate: item.densitate,
                                observatii:'',
                                nr_ord: item.nr_ord,
                                tip_um: item.tip_um
                            }
                            vueInst.storeFavorites.push_product_to_favorites(productForFavorite);
                            vueInst.products[indexInProductsList].isInFavorite=vueInst.storeFavorites.favorites.some(favorite=>{return favorite.productCode==item.productCode})
                            vueInst.$q.notify({
                                color: 'red-6',
                                textColor: 'white',
                                icon: 'favorite',
                                position: 'top',
                                timeout: 500,
                                message: ' Articolul a fost adaugat in favorite'
                            })
                        }
                    })
            }
        }
    }

    public trySendCerereForAnOffer(){
        const vueInst=this;
        if(vueInst.products.length==0){
            vueInst.$q.notify({
                color: 'orange-9',
                textColor: 'white',
                icon: 'shopping_cart',
                position: 'top',
                timeout: 1000,
                message: 'Nu aveti niciun articol in cerere, nu puteti cere o oferta!'
            })
            return false;
        }
        if(vueInst.slidAdresaLivrare.toString().length==0){
            vueInst.$q.notify({
                color: 'orange-9',
                textColor: 'white',
                icon: 'shopping_cart',
                position: 'top',
                timeout: 1000,
                message: 'Indicati adresa de livrare!'
            })
            return false;
        }
        vueInst.setSelectedAdresa(vueInst.slidAdresaLivrare);
        if(vueInst.selectedAdresa.isNewAddress == '1') {
            vueInst.$q.notify({
                color: 'orange-9',
                textColor: 'white',
                icon: 'shopping_cart',
                position: 'top',
                timeout: 1000,
                message: 'Adresa de livarea asteapta aprobare din sales!'
            })
            return false;
        }
        // if(vueInst.nrComandaCerere == vueInst.nrComandaCerereInitial){
        //     vueInst.$q.notify({
        //         color: 'orange-9',
        //         textColor: 'white',
        //         icon: 'shopping_cart',
        //         position: 'top',
        //         timeout: 1000,
        //         message: 'Numar cerere coincide cu cel initial, schimbati numar cerere!'
        //     })
        //     return false;
        // }
        if(new Date(vueInst.termenCerere) < new Date()){
            vueInst.$q.notify({
                color: 'orange-9',
                textColor: 'white',
                icon: 'shopping_cart',
                position: 'top',
                timeout: 1000,
                message: 'Termen de livrare invalid, schimbati termen de livrare!'
            })
            return false;
        }
        if(!vueInst.nrComandaCerere && !vueInst.termenCerere){
            vueInst.$q.dialog({
                title: 'Confirm',
                message: `Nu ati completat termen de solicitare si numar de comanda! Cereti oferta fara ele?`,
                cancel: true,
                persistent: true
            }).onOk(() => {
                vueInst.$q.loading.show();
                vueInst.sendCerereForAnOffer();
            })
        }else{
            vueInst.$q.loading.show();
            vueInst.sendCerereForAnOffer();
        }
    }

    private removeArticle(item:TProductBasketMarkedFavorites,index:number) {
        const vueInst=this;
        vueInst.$q.dialog({
            title: 'Stergere articol - ' +  vueInst.products[index].productName,
            message: 'Sunteti sigur ca doriti sa stergeti acest articol?',
            cancel: true
        })
        .onOk(() => {
            vueInst.products.splice(index,1);
        });
    }

    public sendCerereForAnOffer(){
        const vueInst=this;
        const pidOffer=Math.floor(Math.random() * 10000000).toString();
        ServiceOffer.sendCerereForAnOffer(vueInst.products,vueInst.nrComandaCerere,vueInst.termenCerere,vueInst.slidAdresaLivrare,'', vueInst.inputFreeTextComments).then(response=>{
            vueInst.$q.loading.hide();
            if(response.status=='success'){
                vueInst.EventBusStore.set_event({name:'eventCloseDialogViewOffer',params:{offerId:response.offerId}});
                vueInst.$router.push({name: 'FirstPageAfterPushOffer',  params: { pidOffer:response.offerId }});
            }
        }).catch((error) => {
            vueInst.$q.loading.hide();
            vueInst.$q.notify({
                color: 'red',
                textColor: 'white',
                type: 'negative',
                icon: 'error',
                position: 'top',
                timeout: 3000,
                message: error.toString()
            })
        });
    }

    public moveUP(index: number){
        const vueInst=this;
        if (index > 0) {
            const currentItem = vueInst.products[index];
            currentItem.nr_ord = index-1;
            const newItem = vueInst.products[index-1];
            newItem.nr_ord = index;
            vueInst.products.splice(index, 1,newItem);
            vueInst.products.splice(index-1, 1, currentItem);
        }
    }

    public moveDown(index: number){
        const vueInst=this;
        if (index < vueInst.products.length-1) {
            const currentItem = vueInst.products[index];
            currentItem.nr_ord = index+1;
            const newItem = vueInst.products[index+1];
            newItem.nr_ord = index;
            vueInst.products.splice(index, 1,newItem);
            vueInst.products.splice(index+1, 1, currentItem);
        }
    }


    public getDetaliiCerere(){
        const vueInst=this;
        vueInst.products=[];
        ServiceCerere.getCerere(vueInst.propOfferId).then(response=>{
            if(response.status=='success'){
                //extend(true, vueInst.offerHeader,  response.offerHeader);
                vueInst.offerHeader=JSON.parse(JSON.stringify(response.offerHeader));
                vueInst.products=JSON.parse(JSON.stringify(response.products));
                vueInst.$q.loading.hide();
                vueInst.nrComandaCerere = vueInst.offerHeader.ComandaClient;
                vueInst.nrComandaCerereInitial = vueInst.nrComandaCerere;
                vueInst.termenCerere = vueInst.offerHeader.termenLivrareSolicitat;
                vueInst.inputFreeTextComments = vueInst.offerHeader.observatii_asm ? vueInst.offerHeader.observatii_asm : '';
                ServiceAdreseLivrare.getAdresaLivrare(vueInst.offerHeader.slidAdresaLivrare).then (response=>{
                    if(response.status=='success') {
                        vueInst.adresa = JSON.parse(JSON.stringify(response.adresa))[0];
                        vueInst.slidAdresaLivrare = vueInst.adresa.slid;
                    }
                })
            }
        })
    }

    public optionsDataViitor (date:string) {
        const today = new Date();
        const dd = String(today.getDate()).padStart(2, '0');
        const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        const yyyy = today.getFullYear();

        const todayFmt = yyyy + '/' + mm + '/' + dd;
        return date > todayFmt
    }


    public onEditItem(item:TProductBasket, index:number) {
        const vueInst=this;
        console.log('edit item=%o',item)
        const prodcut_to_replace = vueInst.products[index];
        
        vueInst.changedItemsInOffer = prodcut_to_replace.qUm1 == item.qUm1 || prodcut_to_replace.qUm2 == item.qUm2 || prodcut_to_replace.qBuc == item.qBuc || 
                                      prodcut_to_replace.cuttingLength == item.cuttingLength || prodcut_to_replace.cuttingWidth == item.cuttingWidth;
                                      
        vueInst.products.splice(index,1, {...item, isInFavorite:vueInst.storeFavorites.favorites.some(favorite=>{return favorite.productCode==item.productCode})});
        console.log('Now, products=%o',vueInst.products)
    }

    private browseArticle(){
        const vueInst=this;
        vueInst.showBrowseArticles=true;
    }

    public repetaCerere(){
        const vueInst = this;
        if(!vueInst.changedItemsInOffer) {
            // vueInst.nrComandaCerere = '';
            // vueInst.termenCerere = '';
            // vueInst.slidAdresaLivrare = '';
            // vueInst.inputFreeTextComments = '';
            vueInst.changedItemsInOffer = true;
        }
    }

    @Watch('EventBusStore.event.eventId')
    onEventIdChanged() {
        const vueInst=this;
        if(vueInst.EventBusStore.event.name=='eventBrowseCategories'){
            if(vueInst.EventBusStore.event.params && vueInst.EventBusStore.event.params.pid){
                vueInst.pidForBrowseCategory= vueInst.EventBusStore.event.params.pid;
            }
        }

        if(vueInst.EventBusStore.event.name=='eventBrowseArticles'){
            if(vueInst.EventBusStore.event.params && vueInst.EventBusStore.event.params.pidCategory){
                vueInst.pidForBrowseCategoryHasArticles=true;
                vueInst.pidForBrowseCategory= vueInst.EventBusStore.event.params.pidCategory;
            }
        }

        if(vueInst.EventBusStore.event.name=='eventPutArticleInCerere'){
            if(vueInst.EventBusStore.event.params && vueInst.EventBusStore.event.params.artPentruCerere){
                console.log('vueInst.EventBusStore?.event?.params?.artPentruCerere=%o',)
                const artPentruCerere = JSON.parse(vueInst.EventBusStore.event.params.artPentruCerere);
                vueInst.products.push(artPentruCerere);
                vueInst.showBrowseArticles=false;
                vueInst.pidForBrowseCategory='0';
                vueInst.pidForBrowseCategoryHasArticles=false;
            }
        }

        if(vueInst.EventBusStore.event.name=='closeCurrentView'){
                if(vueInst.showBrowseArticles){
                    vueInst.showBrowseArticles=false;
                    vueInst.pidForBrowseCategory='0';
                    vueInst.pidForBrowseCategoryHasArticles=false;
                }else{
                    vueInst.EventBusStore.set_event({name:'eventCloseDialogViewOffer',params:null});
                }
        }
    }

    public onOpenFormAddNewAdresa(){
        //this.selectedUser=puser
        this.selectedAdresa={
            slid:'0',
            appid: '',
            cif: '',
            adresaCodJudet: '',
            adresaLocalitate: '',
            adresaAdresa: '',
            adresaCodPostal: '',
            tipAdresa: '',
            codTara: '',
            navisionid: '',
            denJudet: '',
            isNewAddress: '1'
        };
        if(this.$q.platform.is.mobile){
            this.dialogTransitionShow ='slide-right';
            this.dialogTransitionHide ='slide-left';
        }else{
            this.dialogTransitionShow ='slide-right';
            this.dialogTransitionHide ='slide-left';
        }
        this.dialogAdresa=true;
    }

    public closeFormEditAdresaLivrare(needRefresh:boolean){
        const vueInst=this;
        vueInst.dialogAdresa=false;
        if(needRefresh){
            vueInst.getAdreseLivrare();
        }
    }

    public getAdreseLivrare(){
        const vueInst=this;
        ServiceAdreseLivrare.getAdreseLivrare().then(response=>{
            if(response.status=='success'){
                vueInst.adrese=JSON.parse(JSON.stringify(response.adrese));
                if(vueInst.adrese.length==1){
                    vueInst.slidAdresaLivrare=vueInst.adrese[0].slid;
                }
            }
        });
    }
    
    public setSelectedAdresa(slid: string){
        const vueInst = this;
        vueInst.adrese.forEach(adresa => {
            if(adresa.slid == slid) {
                vueInst.selectedAdresa = adresa;
            }
        });
        console.log(vueInst.selectedAdresa);
    }


    public created(){
        const vueInst=this;
        vueInst.products=[];
        if(this.propOfferId){
            vueInst.$q.loading.show();
            //extend(true, this.adresa,  this.propAdresa);
            this.getDetaliiCerere();
            vueInst.getAdreseLivrare();
        }
    }
}
