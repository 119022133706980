import {Component, Prop, Watch} from 'vue-property-decorator';
import { Vue, Options } from 'vue-class-component'
import draggable from "vuedraggable";
import {CONFIG_ENV} from '@/config';
import {ServiceAdminNomCategory} from '@/services/ServiceAdminNomCategory';
import user from '@/store/user';
import nomenclatoare from '@/store/nomenclatoare';
import eventbus from '@/store/eventbus';
import {getModule} from "vuex-module-decorators";
import {TUser} from "@/types/TUser";
import {TCategory} from "@/types/TCategory";
import EditArticle from "*.vue";
import {TValueLabel} from "@/types/TValueLabel";
import {TDimensionsOrder} from "@/types/TDimensionsOrder";
import {EnumUM} from "@/types/EnumUM";
import {TUm1Um2} from "@/types/TUm1Um2";

@Options({
    name: "AdminCategory",
    components: {draggable}
})
export default class AdminCategory extends Vue {
    public inputSearchArticle='';
    public isAdminCategoryActivated=false;
    declare public $refs: any;
    public userStore = getModule(user);
    public storeNomenclatoare = getModule(nomenclatoare);
    public EventBusStore = getModule(eventbus);
    public filter = '';
    public isOpenDialogCategory=false;
    public dialogTransitionShow='';
    public dialogTransitionHide='';
    public ImgCategoryString64='';
    public filesSelectedForUpload:any= [];
    public label_editing_category='Denumire categorie';
    public editingCategory:TCategory&TDimensionsOrder&TUm1Um2&{img:any|null,parent_name:string|null,hasChilds:string}={
        appid:'',
        pid:'',
        name:'',
        parent_pid:'',
        parent_name:'',
        category_level:'',
        children:null,
        img:null,
        withLength: '',
        withWidth: '',
        withThickness: '',
        withDiameter: '',
        withHeight: '',
        withAlloy: '',
        withType: '',
        withRollWeight: '',
        is_parent_for_articles: '',
        hasChilds:'n',
        positionLength: 0,
        positionWidth: 0,
        positionThickness: 0,
        positionDiameter: 0,
        positionHeight: 0,
        positionAlloy: 0,
        positionType: 0,
        positionRollWeight: 0,
        um1:'',
        um2:''
    }
    public treeDataCategories:TCategory[]=[];

    public listDimensionsNotUsed:TValueLabel[]= [
        { label: "Lungime", value: 'l' },
        { label: "Latime", value: 'w' },
        { label: "Grosime", value: 't' },
        { label: "Diametru", value: 'd' },
        { label: "Inaltime", value: 'h' },
        { label: "Aliaj", value: 'a' },
        { label: "Tip", value: 'k' },
        { label: "Greutate Rulou", value: 'g' }
    ]
    public listDimensionsUsed:TValueLabel[]= []

    /*
    public treeDataCategories:TCategory[]=[{appid:'1'
                                    ,name:'Aluminiu'
                                    ,category_level:'1'
                                    ,children:[{appid:'2',name:'Table subtiri',category_level:'2',children:[]}
                                              ,{appid:'3',name:'Profile',category_level:'2',children:[{appid:'31',name:'Profile L',category_level:'3',children:[]}
                                                                                                      ,{appid:'32',name:'Profile T',category_level:'3',children:[]}
                                                                                                      ,{appid:'33',name:'Profile U',category_level:'3',children:[]}]}
                                                ]
                                    },
                                    {appid:'10'
                                        ,name:'Cupru'
                                        ,category_level:'1'
                                        ,children:[{appid:'11',name:'Placi Cupru',category_level:'2',children:[]}
                                                  ,{appid:'12',name:'Bare Cupru',category_level:'2',children:[]}
                                        ]
                                    },
                                    {appid:'20'
                                        ,name:'Alama'
                                        ,category_level:'1'
                                        ,children:[]
                                    }];
*/

    get user(): TUser {
        return this.userStore.user;
    }

    get optionsUM(): EnumUM[] {
        return this.storeNomenclatoare.optionsUM;
    }

    resetFilter(){
        this.filter='';
    }

    openFormAddNewCategory(parent_pid: string, parent_name:string){
        const vueInst=this;
        vueInst.onResetFormCategory();
        vueInst.isOpenDialogCategory=true && vueInst.isAdminCategoryActivated;
        vueInst.editingCategory.parent_pid=parent_pid;
        vueInst.editingCategory.parent_name=parent_name;
        if(parent_pid && parent_pid.toString().length>0){
            vueInst.label_editing_category='Denumire subcategorie';
        }else{
            vueInst.label_editing_category='Denumire categorie';
        }
    }

    openForModifyCategory(pid:string){
        const vueInst=this;
        const orderedDimensions:TValueLabel[]=[];
        vueInst.isOpenDialogCategory=true;
        vueInst.onResetFormCategory();
        vueInst.listDimensionsNotUsed= []
        vueInst.listDimensionsUsed=[];
        ServiceAdminNomCategory.getCategory(pid).then(response=>{
            if(response.status=='success'){
                vueInst.editingCategory.pid=pid;
                vueInst.editingCategory.name=response.category.name;
                vueInst.editingCategory.parent_pid=response.category.parent_pid;
                vueInst.editingCategory.parent_name=response.category.parent_name;
                vueInst.editingCategory.withLength=response.category.withLength;
                vueInst.editingCategory.withWidth=response.category.withWidth;
                vueInst.editingCategory.withThickness=response.category.withThickness;
                vueInst.editingCategory.withDiameter=response.category.withDiameter;
                vueInst.editingCategory.withHeight=response.category.withHeight;
                vueInst.editingCategory.withAlloy=response.category.withAlloy;
                vueInst.editingCategory.withType=response.category.withType;
                vueInst.editingCategory.withRollWeight = response.category.withRollWeight;
                vueInst.editingCategory.um1=response.category.um1;
                vueInst.editingCategory.um2=response.category.um2;
                vueInst.editingCategory.positionLength=response.category.positionLength*1;
                vueInst.editingCategory.positionWidth=response.category.positionWidth*1;
                vueInst.editingCategory.positionThickness=response.category.positionThickness*1;
                vueInst.editingCategory.positionDiameter=response.category.positionDiameter*1;
                vueInst.editingCategory.positionHeight=response.category.positionHeight*1;
                vueInst.editingCategory.positionAlloy=response.category.positionAlloy*1;
                vueInst.editingCategory.positionType=response.category.positionType*1;
                vueInst.editingCategory.positionRollWeight=response.category.positionRollWeight*1;
                vueInst.editingCategory.is_parent_for_articles=response.category.is_parent_for_articles;
                vueInst.editingCategory.hasChilds=response.category.hasChilds;
                if(vueInst.editingCategory.parent_pid && vueInst.editingCategory.parent_pid.toString().length>0){
                    vueInst.label_editing_category='Denumire subcategorie';
                }else{
                    vueInst.label_editing_category='Denumire categorie';
                }
                if(response.category.withLength=='y' && response.category.positionLength*1>0){orderedDimensions.push({ label: "Lungime", value: 'l' })}else{vueInst.listDimensionsNotUsed.push({ label: "Lungime", value: 'l' })}
                if(response.category.withWidth=='y' && response.category.positionWidth*1>0){orderedDimensions.push({ label: "Latime", value: 'w' })}else{vueInst.listDimensionsNotUsed.push({ label: "Latime", value: 'w' })}
                if(response.category.withThickness=='y' && response.category.positionThickness*1>0){orderedDimensions.push({ label: "Grosime", value: 't' })}else{vueInst.listDimensionsNotUsed.push({ label: "Grosime", value: 't' })}
                if(response.category.withDiameter=='y' && response.category.positionDiameter*1>0){orderedDimensions.push({ label: "Diametru", value: 'd' })}else{vueInst.listDimensionsNotUsed.push({ label: "Diametru", value: 'd' })}
                if(response.category.withHeight=='y' && response.category.positionHeight*1>0){orderedDimensions.push({ label: "Inaltime", value: 'h' })}else{vueInst.listDimensionsNotUsed.push({ label: "Inaltime", value: 'h' })}
                if(response.category.withAlloy=='y' && response.category.positionAlloy*1>0){orderedDimensions.push({ label: "Aliaj", value: 'a' })}else{vueInst.listDimensionsNotUsed.push({ label: "Aliaj", value: 'a' })}
                if(response.category.withType=='y' && response.category.positionType*1>0){orderedDimensions.push({ label: "Tip", value: 'k' })}else{vueInst.listDimensionsNotUsed.push({ label: "Tip", value: 'k' })}
                if(response.category.withRollWeight=='y' && response.category.positionRollWeight*1>0){orderedDimensions.push({ label: "Greutate Rulou", value: 'g' })}else{vueInst.listDimensionsNotUsed.push({ label: "Greutate Rulou", value: 'g' })}

                if(response.category.withLength=='y' && response.category.positionLength*1>0){orderedDimensions[response.category.positionLength-1]={ label: "Lungime", value: 'l' };}
                if(response.category.withWidth=='y' && response.category.positionWidth*1>0){orderedDimensions[response.category.positionWidth-1]={ label: "Latime", value: 'w' };}
                if(response.category.withThickness=='y' && response.category.positionThickness*1>0){orderedDimensions[response.category.positionThickness-1]={ label: "Grosime", value: 't' };}
                if(response.category.withDiameter=='y' && response.category.positionDiameter*1>0){orderedDimensions[response.category.positionDiameter-1]={ label: "Diametru", value: 'd' };}
                if(response.category.withHeight=='y' && response.category.positionHeight*1>0){orderedDimensions[response.category.positionHeight-1]={ label: "Inaltime", value: 'h' };}
                if(response.category.withAlloy=='y' && response.category.positionAlloy*1>0){orderedDimensions[response.category.positionAlloy-1]={ label: "Aliaj", value: 'a' };}
                if(response.category.withType=='y' && response.category.positionType*1>0){orderedDimensions[response.category.positionType-1]={ label: "Tip", value: 'k' };}
                if(response.category.withRollWeight=='y' && response.category.positionRollWeight*1>0){orderedDimensions[response.category.positionRollWeight-1]={ label: "Greutate Rulou", value: 'g' };}
                vueInst.listDimensionsUsed=orderedDimensions;

                ServiceAdminNomCategory.getImageCategoryAsString(pid).then((response:any)=>{
                    //console.log('getImageCategoryAsString with response=%o',response)
                    vueInst.ImgCategoryString64=response;
                })
            }
        })
    }
    deleteCategory(pid:string,name:string){
        const vueInst=this;
        vueInst.$q.dialog({
            title: 'Confirm',
            message: `Stergeti categoria ${name}?`,
            cancel: true,
            persistent: true
        }).onOk(() => {
            ServiceAdminNomCategory.deleteCategory(pid).then(response=>{
                if(response.status=='success'){
                    vueInst.$q.notify({
                        color: 'teal',
                        textColor: 'white',
                        position:'top',
                        timeout: 500,
                        message: response.message
                    });
                    vueInst.loadTreeDataCategoriesFromDB();
                }
            })
        })
    }

    imgForCategoryWasAdded(file:any){
        const vueInst=this;
        vueInst.editingCategory.img = file[0];
    }

    public onSubmitFormCategory(){
        const vueInst=this;
        vueInst.$q.loading.show();
        console.log('vueInst.filesSelectedForUpload=%o',vueInst.filesSelectedForUpload)
        console.log('onListDimensionsChanged vueInst.listDimensionsUsed=%o',vueInst.listDimensionsUsed)
        vueInst.editingCategory.withLength='n';
        vueInst.editingCategory.withWidth='n';
        vueInst.editingCategory.withThickness='n';
        vueInst.editingCategory.withDiameter='n';
        vueInst.editingCategory.withHeight='n';
        vueInst.editingCategory.withAlloy='n';
        vueInst.editingCategory.withType='n';
        vueInst.editingCategory.withRollWeight='n';
        vueInst.editingCategory.positionLength=0;
        vueInst.editingCategory.positionWidth=0;
        vueInst.editingCategory.positionThickness=0;
        vueInst.editingCategory.positionDiameter=0;
        vueInst.editingCategory.positionHeight=0;
        vueInst.editingCategory.positionAlloy=0;
        vueInst.editingCategory.positionType=0;
        vueInst.editingCategory.positionRollWeight=0;
        console.log('vueInst.editingCategory=%o',vueInst.editingCategory)
        vueInst.listDimensionsUsed.forEach((dimension,index)=>{
            if(dimension.value==='l'){vueInst.editingCategory.withLength='y';vueInst.editingCategory.positionLength=index*1+1;}
            if(dimension.value==='w'){vueInst.editingCategory.withWidth='y';vueInst.editingCategory.positionWidth=index*1+1;}
            if(dimension.value==='t'){vueInst.editingCategory.withThickness='y';vueInst.editingCategory.positionThickness=index*1+1;}
            if(dimension.value==='d'){vueInst.editingCategory.withDiameter='y';vueInst.editingCategory.positionDiameter=index*1+1;}
            if(dimension.value==='h'){vueInst.editingCategory.withHeight='y';vueInst.editingCategory.positionHeight=index*1+1;}
            if(dimension.value==='a'){vueInst.editingCategory.withAlloy='y';vueInst.editingCategory.positionAlloy=index*1+1;}
            if(dimension.value==='k'){vueInst.editingCategory.withType='y';vueInst.editingCategory.positionType=index*1+1;}
            if(dimension.value==='g'){vueInst.editingCategory.withRollWeight='y';vueInst.editingCategory.positionRollWeight=index*1+1;}
        })

        ServiceAdminNomCategory.postCategory(vueInst.editingCategory).then(response=>{
            if(response.status=='success'){
                vueInst.$q.notify({
                    color: 'teal',
                    textColor: 'white',
                    position:'top',
                    timeout: 500,
                    message: response.message
                });
                vueInst.isOpenDialogCategory=false;
                vueInst.onResetFormCategory();
                vueInst.loadTreeDataCategoriesFromDB();
                vueInst.$q.loading.hide();
            }
        });
    }

    public onResetFormCategory(){
        this.editingCategory={
            appid:'',
            pid:'',
            name:'',
            parent_pid:'',
            parent_name:'',
            category_level:'',
            children:null,
            img:null,
            withLength: '',
            withWidth: '',
            withThickness: '',
            withDiameter: '',
            withHeight: '',
            withAlloy: '',
            withType: '',
            withRollWeight: '',
            is_parent_for_articles: '',
            hasChilds:'n',
            positionLength: 0,
            positionWidth: 0,
            positionThickness: 0,
            positionDiameter: 0,
            positionHeight: 0,
            positionAlloy: 0,
            positionType: 0,
            positionRollWeight: 0,
            um1:'',
            um2:''
        }
        this.ImgCategoryString64='';
        if(this.$refs.inputImgForCategory){this.$refs.inputImgForCategory.reset();}
    }

    public myFilterMethod (node:TCategory, filter:string) {
        const filt = filter.toLowerCase()
        console.log('filter=%o',filt)
        return node.name && node.name.toLowerCase().indexOf(filt) > -1;
    }

    public loadTreeDataCategoriesFromDB(){
        const vueInst=this;
        vueInst.$q.loading.show();
        ServiceAdminNomCategory.getNomCategoryTreeData().then(result=>{
            if(result.status=='success'){
                vueInst.treeDataCategories=result.treeDataCategories;
                vueInst.$refs.refTreeGrid.expandAll();
                vueInst.$q.loading.hide();
            }
        });
    }


//vueInst.EventBusStore.set_event({name:'eventClickAddBtn',params:null})
    @Watch('EventBusStore.event.eventId')
    onEventIdChanged() {
        const vueInst=this;
        if(vueInst.EventBusStore.event.name=='eventClickAddBtn'){
            if(vueInst.isAdminCategoryActivated){
                vueInst.openFormAddNewCategory('','');
            }
        }
    }

    public activated(){
        const vueInst=this;
        vueInst.isAdminCategoryActivated=true;
        vueInst.listDimensionsUsed=[];
        this.userStore.set_page_transition('fade-in-right');
        if(this.$q.platform.is.mobile) {
            this.userStore.set_showbackbar(true);
            this.userStore.set_title_back_bar('Administrare Categorii');
        }
        if(this.$q.platform.is.mobile){
            this.dialogTransitionShow ='slide-left';
            this.dialogTransitionHide ='slide-right';
        }else{
            this.dialogTransitionShow ='slide-right';
            this.dialogTransitionHide ='slide-left';
        }
        vueInst.loadTreeDataCategoriesFromDB();
    }

    public deactivated() {
        this.isAdminCategoryActivated = false;
    }
}

