<template>
<div style="display:inline-block;" v-bind:style="styleObject">
{{denumireStatus}}
</div>
</template>

<script>
export default {
  name: "StatusOferta",
  props: {
    status: {
      type: String,
      required: true
    }
  },
  data: function() {
    return {
    }
  },
  computed:{
    denumireStatus: function() {
      let result=this.status;
      if(this.status=='pending'){result='Oferta in lucru';}else{
        if(this.status=='offer'){result='Oferta primita'}else{
          if(this.status=='order'){result='Comanda trimisa'}
        }
      }
      return result;
    },
    styleObject:function (){
      const result= {
        color: 'red',
        fontSize: '0.9rem',
        fontWeight: '500'
      }
      if(this.status=='pending'){result.color='#8896A2';}else{
        if(this.status=='offer'){result.color='#8896A2';}else{
          if(this.status=='order'){result.color='#1AAE9F';}
        }
      }
      return result;
    }
  }
}
</script>

<style scoped>

</style>