import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, withKeys as _withKeys, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, toHandlers as _toHandlers, mergeProps as _mergeProps, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7bdae5d8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"margin-top":"1vh"} }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { style: {"display":"flex","justify-content":"flex-end"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_item_label = _resolveComponent("q-item-label")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_form = _resolveComponent("q-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_q_form, {
        onSubmit: _ctx.onSubmit,
        onReset: _ctx.onReset,
        class: "form__new__user"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_input, {
            outlined: "",
            modelValue: _ctx.user.userid,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.user.userid) = $event)),
            label: "Id utilizator",
            hint: "Va fi folosit de utilizator la conectare",
            class: "form__input",
            onKeydown: _withKeys(_withModifiers(_ctx.focusOnFirstName, ["prevent"]), ["enter"]),
            "lazy-rules": "",
            rules: [ val => val && val.length > 0 || 'Please type something']
          }, null, 8, ["modelValue", "onKeydown", "rules"]),
          _createVNode(_component_q_input, {
            outlined: "",
            ref: "refFirstName",
            modelValue: _ctx.user.firstName,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.user.firstName) = $event)),
            label: "Prenume",
            class: "form__input",
            onKeydown: _withKeys(_withModifiers(_ctx.focusOnLastName, ["prevent"]), ["enter"]),
            "lazy-rules": "",
            rules: [ val => val && val.length > 0 || 'Please type something']
          }, null, 8, ["modelValue", "onKeydown", "rules"]),
          _createVNode(_component_q_input, {
            outlined: "",
            ref: "refLastName",
            onKeydown: _withKeys(_withModifiers(_ctx.focusOnEmail, ["prevent"]), ["enter"]),
            modelValue: _ctx.user.lastName,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.user.lastName) = $event)),
            label: "Nume",
            class: "form__input",
            "lazy-rules": "",
            rules: [ val => val && val.length > 0 || 'Please type something']
          }, null, 8, ["onKeydown", "modelValue", "rules"]),
          _createVNode(_component_q_input, {
            outlined: "",
            ref: "refEmail",
            onKeydown: _withKeys(_withModifiers(_ctx.focusOnPhoneNr, ["prevent"]), ["enter"]),
            modelValue: _ctx.user.emailAddress,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.user.emailAddress) = $event)),
            type: "email",
            label: "Email",
            class: "form__input",
            "lazy-rules": "",
            rules: [ val => val && val.length > 0 || 'Please type something']
          }, null, 8, ["onKeydown", "modelValue", "rules"]),
          _createVNode(_component_q_input, {
            outlined: "",
            ref: "refPhoneNr",
            onKeydown: _withKeys(_withModifiers(_ctx.focusOnFunctie, ["prevent"]), ["enter"]),
            modelValue: _ctx.user.phoneNr,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.user.phoneNr) = $event)),
            label: "Telefon",
            class: "form__input"
          }, null, 8, ["onKeydown", "modelValue"]),
          _createVNode(_component_q_input, {
            outlined: "",
            ref: "refFunctie",
            onKeydown: _withKeys(_withModifiers(_ctx.focusOnCompnay, ["prevent"]), ["enter"]),
            modelValue: _ctx.user.functie,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.user.functie) = $event)),
            type: "text",
            label: "Job/functie",
            class: "form__input"
          }, null, 8, ["onKeydown", "modelValue"]),
          _createVNode(_component_q_select, {
            outlined: "",
            ref: "refCompany",
            label: "Compania",
            class: "form__input",
            modelValue: _ctx.user.companyCode,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.user.companyCode) = $event)),
            "option-value": "code",
            "option-label": "name",
            clearable: "",
            "use-input": "",
            "emit-value": "",
            "map-options": "",
            "input-debounce": "500",
            options: _ctx.optionsCompanies,
            onFilter: _ctx.filterCompanies
          }, {
            option: _withCtx((scope) => [
              _createVNode(_component_q_item, _mergeProps(scope.itemProps, _toHandlers(scope.itemEvents)), {
                default: _withCtx(() => [
                  _createVNode(_component_q_item_section, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_item_label, {
                        innerHTML: scope.opt.cif
                      }, null, 8, ["innerHTML"])
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_q_item_section, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_item_label, {
                        innerHTML: scope.opt.name
                      }, null, 8, ["innerHTML"]),
                      _createVNode(_component_q_item_label, { caption: "" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(scope.opt.code), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1040)
            ]),
            _: 1
          }, 8, ["modelValue", "options", "onFilter"]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_q_btn, {
              label: "Reset",
              type: "reset",
              color: "primary",
              flat: "",
              class: "q-ml-sm",
              "no-caps": ""
            }),
            _createVNode(_component_q_btn, {
              label: "Salveaza",
              type: "submit",
              color: "primary",
              "no-caps": "",
              "text-color": "black"
            })
          ])
        ]),
        _: 1
      }, 8, ["onSubmit", "onReset"])
    ])
  ]))
}